import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Auth/auth.guard';
import { HomecomponentComponent } from './component/home/home.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: '/home', pathMatch: 'full' 
   
  },

  {
    path: 'home',
    loadChildren: './component/home/home.module#HomecomponentModule'
  },
  {
    path: 'about',
    loadChildren: './component/about-us/about-us.module#AboutusModule'
    // component: AboutusComponent
  },
  {
    path: 'services',
    loadChildren: './component/our-services/our-services.module#ServicesModule'

  },
  {
    path: 'clients',
    loadChildren: './component/clients/clients.module#ClientsModule'
  },
  {
    path: 'jobseekers',
    loadChildren: './component/job-seekers/job-seekers.module#JobseekersModule'
  },
  {
    path: 'contact',
    loadChildren: './component/contact-us/contact-us.module#ContactusModule'
  },
  {
    path: 'employeereport',
    loadChildren: './component/employee-reporting/employee-reporting.module#EmployeereportModule'
  },
  {
    path: 'blogs',
    loadChildren: './component/blog-list/blog-list.module#BlogModule',
  },

  {
    path: 'blogdetail',
    loadChildren: './component/blog-details/blog-details.module#BlogdetailsModule',
  },
  {
    path: 'news',
    loadChildren: './component/news-list/news-list.module#NewsModule',
  },
  {
    path: 'newsdetail',
    loadChildren: './component/news-details/news-details.module#NewsdetailsModule',
  },
  {
    path: 'team',
    loadChildren: './component/our-team/our-team.module#OurteamModule',
  },
  {
    path: 'gallery',
    loadChildren: './component/gallery/gallery.module#GalleryModule',
  },
  {
    path: 'medical',
    loadChildren: './component/medical-list/medical-list.module#MedicalListModule',
  },
  {
    path: 'medicaldetail',
    loadChildren: './component/medical-details/medical-details.module#MedicalDetailsModule',
  },
  {
    path: 'login',
    loadChildren: './component/login/login.module#LoginModule',
  },

  {
    path: 'sharedetail',
    loadChildren: 'src/app/component/resume/resume.module#ResumePostingModule',
  
  },
  {
    path: 'archivejobs',
    loadChildren: 'src/app/component/archive-jobs/archive-jobs.module#ArchivejobsModule',
    
  },
  {
    path: 'archivejobdetails',
    loadChildren: 'src/app/component/archive-job-details/archive-job-details.module#ArchiveJobDetailsModule',
   
   },

  // Admin Routes
  {
    path: 'addblog',
    loadChildren: 'src/app/admin/component/crud-blog/crud-blog.module#AddBlogModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'bloglist',
    loadChildren: 'src/app/admin/component/blog-list/blog-list.module#BlogModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'addnews',
    loadChildren: 'src/app/admin/component/crud-news/crud-news.module#AddNewsModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'newslist',
    loadChildren: 'src/app/admin/component/news-list/news-list.module#NewsListModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: 'src/app/admin/component/home/home.module#AdminDashboardModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'teamlist',
    loadChildren: 'src/app/admin/component/our-team/our-team.module#TeamModule',
    canActivate:[AuthGuard]
   },
  {
    path: 'addteam',
    loadChildren: 'src/app/admin/component/crud-our-team/crud-our-team.module#AdminTeamModule',
    canActivate:[AuthGuard]
   },
  {
    path: 'gallerylist',
    loadChildren: 'src/app/admin/component/gallery-list/gallery-list.module#GalleryListModule',
    canActivate:[AuthGuard]
   },
  {
    path: 'addgallery',
    loadChildren: 'src/app/admin/component/crud-gallery/crud-gallery.module#CrudGalleryModule',
    canActivate:[AuthGuard]
  },


  
  {
    path: 'archivejobslist',
    loadChildren: 'src/app/admin/component/archive-job-list/archive-job-list.module#ArchiveJobListModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'addarchivejob',
    loadChildren: 'src/app/admin/component/crud-archive-job/crud-archive-job.module#CrudArchiveJobModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'uarchivelist',
    loadChildren: 'src/app/admin/component/user-archive-job-list/user-archive-job-list.module#UserArchiveJobListModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'uarchivedetail',
    loadChildren: 'src/app/admin/component/user-archive-job-details/user-archive-job-details.module#UserArchiveJobDetailsModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'medicallist',
    loadChildren: 'src/app/admin/component/medical-list/medical-list.module#MedicalListModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'addmedical',
    loadChildren: 'src/app/admin/component/crud-medical/crud-medical.module#CrudMedicalModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'addtitle',
    loadChildren: 'src/app/admin/component/crud-title/crud-title.module#CrudTitleModule',  canActivate:[AuthGuard]
  },
  {
    path: '**',
    component: HomecomponentComponent
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
