import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router, RouterModule, PreloadAllModules, NavigationEnd } from '@angular/router';
import { ServerService } from 'src/app/Shared/Services/server.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-homecomponent',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomecomponentComponent implements OnInit {
  current = 0;
  @ViewChild('myModal') myModal: ElementRef;
  model: any = {};
  LatestNews: any;
  LatestBlogs: any;
  BlogDate: any;
  BlogDescription: any;
  BlogTitle: any;
  BlogImage: any;
  NewsDate: any;
  NewsDescription: any;
  NewsTitle: any;
  NewsImage: any;
  show: boolean = true;
   carousel : Element= document.querySelector('.carousell');
  
 cellCount : any= 9;
 selectedIndex: any = 0;
  BlogId: any;
  NewsId: any;

//  img_list = [
//   'assets/Images/develop.jpg',
//   'assets/Images/staffing.jpg',
//   'assets/Images/consult.jpg',
//  ' assets/Images/train.jpg'
// ];
  constructor(private serverService: ServerService, private router: Router, //private location: Location
    ) {
   
  }


  ngOnInit() {
       this.loadExternalScript('assets/js/scroll.js');
    
    this.FetchNews();
    this.FetchBlog();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
   // this.location.replaceState('');
  }



  public loadExternalScript(url: string) {
   
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  // public loadExternalCss(url: string) {
  //   const body = <HTMLDivElement> document.body;
  //   const script = document.createElement('link');
  //   script.innerHTML = '';
  //   script.href = url;
  //   script.rel = "stylesheet";
  //   script.type = "text/css";
  //   body.appendChild(script);
  // }
  redirect() {
    window.scrollTo(0, 0);
  }

  FetchNews() {
    this.model.AccountId = 1
    this.serverService.FetchArticles(this.model).subscribe(
      (data: any) => {

        // console.log(data["ArticleData"]);
        const filterValue = "NEWS";
        const result = data.ArticleList.filter(element => {
          return element.ArticleType === filterValue;
        });
        data = result;
        if(data.length!=0){
        this.LatestNews = data[0];
        this.NewsId = data[0].ArticleId;
        this.NewsDate = data[0].ArticleDate;
        this.NewsDescription = data[0].ArticleDescription;
        this.NewsTitle = data[0].ArticleTitle;
        this.NewsImage = data[0].ImagePath;
      } else {
        this.NewsId = "";
        this.NewsDate = "";
        this.NewsDescription = "";
        this.NewsTitle = "";
        this.NewsImage = "";
      }
      }
    );
  }

  FetchBlog() {
    this.model.AccountId = 1

    this.serverService.FetchArticles(this.model).subscribe(
      (data: any) => {
        const filterValue = "BLOG";
        const result = data.ArticleList.filter(element => {
          return element.ArticleType === filterValue;
        });
        data = result;
     
        if(data.length != 0){
        this.LatestBlogs = data[0];
        this.BlogId = data[0].ArticleId;
        this.BlogDate = data[0].ArticleDate;
        this.BlogDescription = data[0].ArticleDescription;
        this.BlogTitle = data[0].ArticleTitle;
        this.BlogImage = data[0].ImagePath;
      } else{
        this.BlogId = "";
        this.BlogDate = "";
        this.BlogDescription = "";
        this.BlogTitle = "";
        this.BlogImage = "";
      }
      });
  }

  ViewBlog(blogId) {
  //  this.serverService.setdata(blog);
    const EncodeURL = btoa(blogId);
    // console.log(EncodeURL);
    this.router.navigate(["/blogdetail"], { queryParams: { q: EncodeURL } });
  }
  ViewNews(newsId){
    // this.serverService.setdata(news);
    // this.router.navigateByUrl("/newsdetail" );

    const EncodeURL = btoa(newsId);
    // console.log(EncodeURL);
    this.router.navigate(["/newsdetail"], { queryParams: { q: EncodeURL } });
    
  }


}


