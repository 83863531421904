import { Component, OnInit } from '@angular/core';

import {Router, ActivatedRoute} from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  navSts:any=true;
  constructor(public router: Router) { }
  navbarResponsive: boolean;
  toggleNavbar() {
    this.navbarResponsive = !this.navbarResponsive;
  }
  
  ngOnInit() {
    
  }
  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('adminID');
    this.router.navigate(['/home'])
    .then(()=>{
      window.location.reload();
    })
    
  
  }
  
  redirect(){
    this.checkMob2();
    if(this.navSts==true){
      $('#navbarResponsive').removeClass('show');
    }else{
      
  $('#navbarResponsive').addClass('show');
   }
   this.navSts=!this.navSts;
    window.scrollTo(0,0);
    }
    checkMob2(){
      this.navSts= true;
      $('#topnavmobile').removeClass('show');
    }
}
