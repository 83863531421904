import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import {FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule  } from '@angular/common/http'
import { EmployeereportComponent } from './component/employee-reporting/employee-reporting.component';
import { RouterModule } from '@angular/router';
import {  BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomecomponentModule } from './component/home/home.module';

import { CommonModule } from '@angular/common';
import { FooterComponent } from './Core/footer/footer.component';
// import { TeamComponent } from './admin/component/our-team/our-team.component';
// import { AdminBlogComponent } from './admin/admin-blog/admin-blog.component';
import { ServerService } from './Shared/Services/server.service';
import {ImagevalidationService} from './Shared/Services/imagevalidation.service';
import { HeaderComponent } from './Core/header/header.component';
import { AdminHeaderComponent } from './admin/core/header/header.component';
import { SharedModule } from './Shared/shared.module';
import { GlobalErrorService } from './Shared/Services/global-error.service';
import { CrudTitleComponent } from './admin/component/crud-title/crud-title.component';
// import { EditorModule } from '@progress/kendo-angular-editor';
// import { RemoveHTMLTagsPipe } from './Shared/Pipes/remove-htmltags.pipe';
// import { MedicalListComponent } from './component/medical-list/medical-list.component';
// import { MedicalDetailsComponent } from './component/medical-details/medical-details.component';
// import { MedicalComponent } from './component/medical/medical.component';
// import { from } from 'rxjs';
// import { BlogComponent } from './admin/component/blog-list/blog-list.component';
// import { BlogModule } from './admin/component/blog-list/blog-list.module';
// import { AddBlogComponent } from './admin/component/crud-blog/crud-blog.component';
// import { AddNewsComponent } from './admin/component/crud-news/crud-news.component';
// import { NewsListComponent } from './admin/component/news-list/news-list.component';
// import { ResumePostingComponent } from './component/resume/resume.component';
// import { GalleryListComponent } from './admin/component/gallery-list/gallery-list.component';
// import { CrudGalleryComponent } from './admin/component/crud-gallery/crud-gallery.component';
// import { AdminDashboardModule } from './admin/component/home/home.module';

// import { RemoveHTMLTagsPipe } from './Shared/Pipes/remove-htmltags.pipe';
// import { UserArchiveJobListComponent } from './admin/component/user-archive-job-list/user-archive-job-list.component';
// import { UserArchiveJobDetailsComponent } from './admin/component/user-archive-job-details/user-archive-job-details.component';
// import { ArchiveJobListComponent } from './admin/component/archive-job-list/archive-job-list.component';
// import { CrudArchiveJobComponent } from './admin/component/crud-archive-job/crud-archive-job.component';
// import { ArchiveJobDetailsComponent } from './component/archive-job-details/archive-job-details.component';

@NgModule({
  declarations: [
    AppComponent,
    EmployeereportComponent,
    FooterComponent,
    HeaderComponent,
    AdminHeaderComponent,
    
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    HomecomponentModule,
    HttpClientModule, SharedModule
    // BlogModule,
    ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorService
    },
    ServerService, ImagevalidationService
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
