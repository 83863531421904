import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHTMLTags'
})
export class RemoveHTMLTagsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    return value.replace(/<[^>]+>/gm, '');
 
  }

}
