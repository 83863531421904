import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  navbarResponsive: boolean;
  //adminname: any = localStorage.getItem('adminID');
  constructor() { }

  ngOnInit() {
  }
  toggleNavbar() {
  
    this.navbarResponsive = !this.navbarResponsive;
  }


  
  navSts:any=true;
  redirect(){
    this.checkMob2();
    if(this.navSts==true){
      $('#navbarResponsive').removeClass('show');
    }else{
      
  $('#navbarResponsive').addClass('show');
   }
   this.navSts=!this.navSts;
    window.scrollTo(0,0);
    }
    navSts1:any=true;
    mobilenav(){
      this.checkMob();
      if(this.navSts1==true){
        $('#topnavmobile').removeClass('show');
      }else{
        $('#topnavmobile').addClass('show');
     }
     this.navSts1=!this.navSts1;
      window.scrollTo(0,0);
      }
      checkMob(){
        this.navSts1= true ;
        $('#navbarResponsive').removeClass('show');
      }
      checkMob2(){
        this.navSts= true;
        $('#topnavmobile').removeClass('show');
      }
      removeactive(){
      
        $('#removeactive').removeClass('active');
      }
}

