import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveHTMLTagsPipe } from './Pipes/remove-htmltags.pipe';

@NgModule({
  declarations: [RemoveHTMLTagsPipe],
  imports: [
    CommonModule
  ],
  exports:[RemoveHTMLTagsPipe]
})
export class SharedModule { }
