import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentYear: number;
  constructor() { }

  // redirect to windows top 
// redirect(){
//   window.scrollTo(0,0);
  
//   }
  // TODO: Cross browsing
  redirect() {
    try 
    { 
     window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
     } catch (e) {
      window.scrollTo(0, 0);
      }
    // window.scrollTo(0, 0);
    // window.scroll({ 
    //   top: 0, 
    //   left: 0, 
    //   // behavior: 'smooth' 
    // });
  }
  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

}
