import { Component, HostListener, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute, RouterEvent, NavigationStart, NavigationEnd} from '@angular/router';
import { ServerService } from 'src/app/Shared/Services/server.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  @ViewChild('WelcomeModal') WelcomeModal: ElementRef;
  adminname: any = localStorage.getItem('adminID');
  memberID : any;
  data : any;
  model: any ={};
  loading: boolean;
  constructor(
    public router: Router,
    private http: HttpClient,
    private serverService: ServerService
  ) { 

    this.loading = false;
    
    router.events.subscribe(
      (event: RouterEvent): void => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        } else if (event instanceof NavigationEnd) {
          this.loading = false;
        }
      }
    );
    
  }
  ngOnInit() {
    //alert(this.adminname);
   this.refreshAdmin();
  }

refreshAdmin(){
  if(this.adminname == '1'){
   
    const EncodeURL = btoa('Admin Dashboard');
    // console.log(EncodeURL);
    this.router.navigate(["/dashboard"], { queryParams: { q: EncodeURL } });
  }
  // else{
  //   // this.WelcomeModal.nativeElement.click();
  //    // this.router.navigateByUrl("")
  // }
}

}
