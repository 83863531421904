import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

 const API_URL=environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  gettoken(){  
       
    return  !!localStorage.getItem("adminID"); 
    }
}
