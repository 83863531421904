import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-employeereport',
  templateUrl: './employee-reporting.component.html',
  styleUrls: ['./employee-reporting.component.css']
})
export class EmployeereportComponent implements OnInit {

  constructor(//private location: Location
    ) { }

  ngOnInit() {
    //this.location.replaceState('');
  }

}
