import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerService } from './server.service';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class GlobalErrorService implements ErrorHandler {
    errorMessage: any;
    displaymessage: any;
    obj: any ;
     constructor(private injector: Injector, private serverService: ServerService ) { }

    handleError(error: any): void {

        const router = this.injector.get(Router);
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message))
        {
         window.location.reload();
         }

        this.obj = {};
        if (error instanceof HttpErrorResponse) {
            this.obj.ErrorMessage = 'Http Error :-' + error.message;
            console.log(this.obj.ErrorMessage);
            // this.serverService.ExceptionLog(this.obj).subscribe(res => {
            //     this.displaymessage = res;
            //     console.log(this.displaymessage);
            // });

            // router.navigate(['error'], { skipLocationChange: true });
        }        else {
            this.errorMessage = error;
            this.obj.ErrorMessage = 'Other Error :- ' + error.message;
            console.log(this.obj.ErrorMessage);
          //   this.serverService.ExceptionLog(this.obj).subscribe(res => {
          //   this.displaymessage = res;
          //   console.log(this.displaymessage);
          // });

        }

        // console.log(error.message) ;
           }
}
