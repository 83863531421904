import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomecomponentComponent } from 'src/app/component/home/home.component';
import { RouterModule, Routes } from '@angular/router';
//import { HeaderComponent } from '../header/header.component';


export const routes: Routes =[
  {
      path: '',
      component: HomecomponentComponent

  }
]

@NgModule({
  declarations: [
    HomecomponentComponent],
  exports: [HomecomponentComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ]
})
export class HomecomponentModule { }

