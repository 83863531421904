import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagevalidationService {

  imageChangedEvent: any;
  ConstFileSize: number;
  fileSize: number;
  selectedFile: any;
  url: any;
  message: any;
  
  ValidateImage(event) {
    try {
     
      if (event.target.files[0]) {
        if (event.target.files[0].size > 0) {
          if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type === 'image/jpeg' ||
              event.target.files[0].type === 'image/png' ||
              event.target.files[0].type === 'image/jpg') {
              this.imageChangedEvent = event;
              // tslint:disable-next-line:prefer-const
              if (event.target.files[0]) {
                this.ConstFileSize = 3145728;  // 3MB in bytes

                this.fileSize = event.target.files[0].size;

                if (this.fileSize <= this.ConstFileSize) {
                  return true;
                } else {
                  return this.message = 'Image size should not exceed 3MB';
                }
              }
            } else {
              return this.message = 'Please, Upload Image types of  PNG, JPG, JPEG.';
            }
          }
        } else {
          return this.message = 'Filename does not exist or is zero length';

        }
      }
    } catch (error) {
      throw error;
    }
  }


  Validatefile(event) {
    try {
     
      if (event.target.files[0]) {
        if (event.target.files[0].size > 0) {
          if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type === '.doc' ||
              event.target.files[0].type === '.pdf' ||
              event.target.files[0].type === '.docx') {
              this.imageChangedEvent = event;
              // tslint:disable-next-line:prefer-const
              return true;
              
            } else {
              return this.message = 'Please, Upload File types of  doc, pdf, docx.';
            }
          }
        } else {
          return this.message = 'Filename does not exist or is zero length';

        }
      }
    } catch (error) {
      throw error;
    }
  }

  valiadtepdfdoc(event){
    try{
    const file= event.target.files[0].name;
    const len= file.length;
    const ext =file.slice(len-4, len)
    const doc = file.slice(len-5, len)

    if(event.target.files[0]){
    if((ext.toUpperCase() == ".PDF")||(ext.toUpperCase() == ".DOC")||(doc.toUpperCase() == ".DOCX")){
      return  true;
    }
    else{
      
      return false;
      
    }
  }
  else {
    return 0
  }
  } catch(err){
    console.log(err);
  }

  }
}
